
@import url('https://fonts.googleapis.com/css2?family=Rubik+Gemstones&family=Rubik+Moonrocks&display=swap');

.rubik-gemstones-regular {
  font-family: "Rubik Gemstones", system-ui;
  font-weight: 400;
  font-style: normal;
}

.rubik-moonrocks-regular {
  font-family: "Rubik Moonrocks", sans-serif;
  font-style: normal;
}

.frame{
  pointer-events: none;
}